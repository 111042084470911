import React, {useReducer} from 'react';
import SessionStateContext from '../context/SessionStateContext';
import DispatchContext from '../context/DispatchContext';
import {reducer} from '../../reducer';

const initialSessionState = {
  nav_history: [],
  timestamp: new Date(),
  serviceRequestInsert: 0,
  serviceRequestLastFetch: {},
  serviceRequestUpdate: {},
  app_loading: false,
  user_settings: {},
  show_logout_dialog: false,
  show_tecrmi_dialog: null,
  messages: [],
};

export let StaticDispatch = null;
export let StaticState = null;

const storeReducer = (state, action) => {
  const newState = reducer(state, action);
  return newState;
};

export default (App) => {
  const WrappedApp = (props) => {
    const [sessionState, dispatch] = useReducer(
      storeReducer,
      initialSessionState,
    );

    StaticDispatch = dispatch;
    StaticState = sessionState;

    return (
      <SessionStateContext.Provider value={sessionState}>
        <DispatchContext.Provider value={dispatch}>
          <App {...props} />
        </DispatchContext.Provider>
      </SessionStateContext.Provider>
    );
  };

  return WrappedApp;
};
