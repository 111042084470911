import React, {useEffect, useState} from 'react';
import {View, TextInput} from 'react-native';
import moment from 'moment';

import sy from '~/styles';
import {
  user as userApi,
  service_request as serviceRequestApi,
} from '~/api/private';

import {
  Text,
  Persona,
  Pressable,
  Menu,
  Dialog,
  Thumbnail,
  Document,
  DealerPersona,
  Link,
} from '~/components/controls';
import {PersonaSize} from '~/components/controls/Persona';

import MoreVertIcon from '~/images/md-icons/more_vert/materialicons/24px.svg';
import EditIcon from '~/images/md-icons/edit/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import DoneIcon from '~/images/md-icons/done/materialicons/24px.svg';
import DoneAllIcon from '~/images/md-icons/done_all/materialicons/24px.svg';
import DocsIcon from '~/images/md-icons/docs/materialsymbolsoutlined/24px.svg';

const LINE_HEIGHT = 24;

const EditDialog = ({visible, content, onDismiss, onEdit}) => {
  const [value, setValue] = useState('');
  const [lines, setLines] = useState(1);

  useEffect(() => {
    setValue(content ?? '');
  }, [content]);

  return (
    <Dialog
      visible={visible}
      title="Bericht bewerken"
      onDismiss={onDismiss}
      buttons={[
        {
          text: 'Annuleren',
          onPress: onDismiss,
        },
        {
          text: 'Gereed',
          onPress: () => {
            onEdit(value);
            onDismiss();
          },
        },
      ]}>
      <View style={[sy['py-4']]}>
        <View
          style={[
            sy['flex-row'],
            sy['flex-1'],
            sy['p-2'],
            {
              borderRadius: 10,
              backgroundColor: '#EBEBEB',
            },
          ]}>
          <TextInput
            style={[
              sy.regular,
              sy['flex-1'],
              {
                color: value.length > 0 ? '#4A4A49' : '#828282',
                height: lines * LINE_HEIGHT,
              },
            ]}
            multiline
            placeholder={'Typ om het bericht te wijzigen'}
            value={value}
            onChangeText={setValue}
            onContentSizeChange={({nativeEvent}) => {
              const {height} = nativeEvent.contentSize;
              setLines(Math.ceil(height / LINE_HEIGHT));
            }}
          />
        </View>
      </View>
    </Dialog>
  );
};

const Message = (props) => {
  const {
    style,
    content,
    attachments,
    timestamp: message_timestamp,
    message_id,
    request_id,
    user_id,
    dealer_id,
    dealer_name,
    fleet_owner_name,
    lessor,
    is_sent,
    onEdit,
    onDelete,
  } = props;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [is_read, setIsRead] = useState(props.is_read);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [showEditMessageDialog, setShowEditMessageDialog] = useState(null);
  const [showDeleteMessageDialog, setShowDeleteMessageDialog] = useState(null);

  useEffect(() => {
    const touch = async () => {
      await serviceRequestApi.message_read(request_id, message_id);
      setIsRead(true);
    };

    if (message_id) {
      touch();
    }
  }, [message_id, request_id]);

  useEffect(() => {
    const fetch = async () => {
      const {first_name, last_name} = (await userApi.find(user_id)) ?? {};

      if (first_name && last_name) {
        let initials = first_name.substring(0, 2);
        if (last_name.length > 0) {
          initials = `${first_name[0]}${last_name[0]}`;
        }

        setUser({initials, name: `${first_name} ${last_name}`.trim()});
      }

      setLoading(false);
    };

    if (user_id) {
      fetch();
    } else {
      setLoading(false);
    }
  }, [user_id]);

  let duration;
  if (message_timestamp) {
    const timestamp = moment(message_timestamp);
    if (timestamp.isSame(moment(), 'day')) {
      duration = timestamp.format('H:mm');
    } else {
      duration = timestamp.format('D MMMM YYYY');
    }
  }

  return (
    <>
      <View style={[sy['flex-row'], sy['overflow-hidden'], {gap: 22}, style]}>
        {dealer_name ? (
          <DealerPersona dealer_id={dealer_id} size={PersonaSize.size36} />
        ) : (
          <Persona
            is_dealer={false}
            style={[sy['self-center']]}
            imageInitials={(fleet_owner_name ?? lessor).substring(0, 2)}
            lessor={fleet_owner_name ?? lessor}
            size={PersonaSize.size36}
          />
        )}
        <View style={[sy['flex-1']]}>
          <View
            style={[
              sy['flex-row'],
              sy['items-center'],
              sy['justify-between'],
              sy['pb-1'],
            ]}>
            <div data-private>
              <Text style={[sy.mediumBold, {lineHeight: 16}]}>
                {user.name ??
                  (loading ? (
                    <>&nbsp;</>
                  ) : (
                    dealer_name ?? fleet_owner_name ?? lessor
                  ))}
              </Text>
            </div>
            <View style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
              <Text style={[sy.smallRegular, {lineHeight: 14}]}>
                {duration}
              </Text>
              {onEdit || onDelete ? (
                <Menu
                  visible={isMenuVisible}
                  anchor={
                    <MoreVertIcon height={18} width={18} fill="#4a4a49" />
                  }
                  style={{marginTop: 24}}
                  contentStyle={{backgroundColor: '#6D6D6D'}}
                  onShow={() => setIsMenuVisible(true)}>
                  <View style={[sy['px-2'], sy['flex-row'], sy['gap-4']]}>
                    {onEdit &&
                      !attachments?.length /* messages with attachments can't be edited */ && (
                        <Pressable
                          onPress={() => {
                            setShowEditMessageDialog(true);
                            setIsMenuVisible(false);
                          }}>
                          <EditIcon height={18} width={18} fill="#ffffff" />
                        </Pressable>
                      )}
                    {onDelete && (
                      <Pressable
                        onPress={() => {
                          setShowDeleteMessageDialog(true);
                          setIsMenuVisible(false);
                        }}>
                        <DeleteIcon height={18} width={18} fill="#ffffff" />
                      </Pressable>
                    )}
                  </View>
                </Menu>
              ) : is_read && is_sent ? (
                <DoneAllIcon width={14} height={14} fill="#231fda" />
              ) : is_sent ? (
                <DoneIcon width={14} height={14} fill="#231fda" />
              ) : null}
            </View>
          </View>
          <Text style={sy.smallRegular}>{content}</Text>
          {attachments?.length > 0 && (
            <>
              {attachments?.map((item, index) => {
                if (item.is_image) {
                  return (
                    <React.Fragment key={index}>
                      <Thumbnail
                        request_id={request_id}
                        message_id={message_id}
                        index={index}
                        data={item.data}
                      />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <View
                      key={index}
                      style={[sy['flex-row'], sy['items-center'], sy['gap-2']]}>
                      <View style={[sy['shrink-0']]}>
                        <DocsIcon width={24} height={24} fill="#4a4a49" />
                      </View>
                      <Link
                        onPress={async () => {
                          const w = window.open();
                          w.document.write(
                            `<html style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
                                <body style="margin: 0; padding: 0; overflow: auto; height: 100%; width: 100%; background-color: #f2f2f2;">
                                  <div onclick="window.close();" style='position: absolute;background-color: #fff; padding: 4px;'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div>
                                  <div style='text-align: center; margin-top: 200px;'>Bezig met laden...</div>
                                </body>
                            </html>`,
                          );

                          const {result} =
                            (await serviceRequestApi.attachment(
                              request_id,
                              message_id,
                              index,
                            )) ?? {};

                          if (!result) {
                            w.document.body.innerHTML = `
                              <div onclick="window.close();" style='position: absolute;background-color: #fff; padding: 4px;'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div>
                              <div style='text-align: center; margin-top: 200px;'>Document laden is mislukt. Probeer het opnieuw.</div>
                            `;
                            w.document.close();

                            setTimeout(() => {
                              w.close();
                            }, 2000);
                            return;
                          }

                          w.document.body.innerHTML = `
                            <div onclick="window.close();" style='position: absolute;background-color: #fff; padding: 4px;'><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></div>
                            <iframe id="viewer" width="100%" height="100%" />
                          `;

                          const script = w.document.createElement('script');
                          script.text = `
                              const byteArray = Uint8Array.from(atob('${
                                result.split('base64,')[1]
                              }'), c => c.charCodeAt(0));
                              const blob = new Blob([byteArray], { type: 'application/pdf' });

                              const viewer = document.getElementById('viewer');
                              const objectUrl = URL.createObjectURL(blob);

                              viewer.setAttribute('src', objectUrl);
                              viewer.onload = () => URL.revokeObjectURL(objectUrl);
                          `;

                          w.document.body.appendChild(script);

                          w.document.close();
                        }}
                        style={[sy['flex-1']]}
                        textStyle={[sy['truncate']]}>
                        {item.name.substring(0, item.name.indexOf('.'))}
                      </Link>
                    </View>
                  );
                }

                return null;
              })}
            </>
          )}
        </View>
      </View>
      <EditDialog
        visible={showEditMessageDialog}
        content={content}
        onDismiss={() => setShowEditMessageDialog(false)}
        onEdit={(value) => onEdit(message_id, value)}
      />
      <Dialog
        visible={showDeleteMessageDialog}
        title="Bericht verwijderen?"
        buttons={[
          {
            text: 'Annuleren',
            onPress: () => setShowDeleteMessageDialog(false),
          },
          {
            text: 'Verwijderen',
            onPress: () => {
              onDelete(message_id);
              setShowDeleteMessageDialog(false);
            },
          },
        ]}>
        <View style={[sy['py-4']]}>
          <Text>Verwijderen kan niet ongedaan worden gemaakt.</Text>
        </View>
      </Dialog>
    </>
  );
};

export default Message;
