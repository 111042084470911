import React, {useEffect, useState, useCallback} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';
import _ from 'lodash';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  Pressable,
  Link,
  FourceDetailsLink,
  ArticleText,
  ActionButton,
  Chip,
  ArticleTooltip,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {AddPartDialog, SlaArticleDialog} from '~/components//shared/Dialogs';
import {
  articles as articlesApi,
  service_request as serviceRequestApi,
  dealer as dealerApi,
  rob as robApi,
} from '~/api/private';
import Format from '~/lib/format';
import {SOURCES} from '~/types/sources';
import withDimensions from '~/components/hoc/with-dimensions';

import AddIcon from '~/images/md-icons/add/materialicons/24px.svg';
import DeleteIcon from '~/images/md-icons/delete/materialicons/24px.svg';
import UnfoldMoreIcon from '~/images/md-icons/unfold_more/materialicons/24px.svg';

const HEADER_HEIGHT_IN_PX = 133;
const FOOTER_HEIGHT_IN_PX = 50;

const FILTER = {
  history: 'history',
  preferred: 'preferred',
  all: 'all',
};

const AddArticle = ({
  dismissable = true,
  onDismiss,
  dimensions,
  request_id,
  license,
  dealer_id,
  description,
  gen_arts,
  item_mp_id,
  number,
  reference,
  sla_part,
  task_part,
  lessor,
  rob_code,
  onChange,
  onRemove,
}) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const [articles, setArticles] = useState([]);
  const [editCustomPart, setEditCustomPart] = useState(null);
  const [showAddEditPartDialog, setShowAddEditPartDialog] = useState(false);
  const [slaArticleBrands, setSlaArticleBrands] = useState([]);
  const [showFilter, setShowFilter] = useState(FILTER.all);
  const [articlesFilter, setArticlesFilter] = useState([]);
  const [showAllArticles, setShowAllArticles] = useState(false);

  const is_sla_task_part =
    task_part?.reference?.startsWith('SLA_TASK|') &&
    _.isNumber(task_part?.price);
  const canAddPart = !is_sla_task_part;

  const refresh = useCallback(async () => {
    let articles = [];
    if (sla_part) {
      articles = [sla_part];
    } else {
      let result;
      if (item_mp_id && `${item_mp_id}`.match(/^\d+$/) !== null) {
        const response = await articlesApi.search_by_item_mp_ids(
          license,
          request_id,
          dealer_id,
          [item_mp_id],
        );

        if (response.success) {
          result = response.result.articles;
          setArticlesFilter(response.result.filter);
        }
      } else if (gen_arts?.length) {
        const response = await articlesApi.search_by_gen_arts(
          license,
          dealer_id,
          gen_arts,
        );

        if (response.success) {
          result = response.result.articles;
        }
      }

      const {result: sla_result} = await serviceRequestApi.sla(request_id);
      setSlaArticleBrands(sla_result?.article_brands ?? []);

      const {result: dealer_result} = await dealerApi.parts(dealer_id, license);

      const dealer_parts = (
        sla_result?.dealer_parts?.filter((item) =>
          gen_arts?.includes(item.gen_art_nr),
        ) ?? []
      )
        .concat(
          dealer_result?.filter(
            (item) =>
              item.dealer_id === dealer_id &&
              parseInt(item.item_mp_id) === parseInt(item_mp_id),
          ) ?? [],
        )
        .sort((a, b) => a.price - b.price);

      articles = [...dealer_parts, ...(result ?? [])];
    }

    if (
      articles.every((item) => item.reference !== task_part?.reference) &&
      _.isNumber(task_part?.price)
    ) {
      articles = [
        {
          description,
          number,
          price: task_part.price,
          source: task_part.source,
          reference: task_part.reference,
        },
        ...articles,
      ];
    }

    if (rob_code) {
      const {result: code_articles} = await robApi.articles(
        dealer_id,
        lessor,
        rob_code,
      );

      articles = articles.map((item) => ({
        ...item,
        rob_article: code_articles.find(
          (x) => x.reference === item.reference || x.number === item.number,
        ),
      }));

      if (articles.every((item) => item.rob_article)) {
        setShowFilter(FILTER.all);
      } else if (articles.some((item) => item.rob_article)) {
        setShowFilter(FILTER.history);
      }
    }

    setArticles(articles);

    return articles;
  }, [
    request_id,
    license,
    dealer_id,
    gen_arts,
    item_mp_id,
    sla_part,
    description,
    number,
    task_part,
    lessor,
    rob_code,
  ]);

  useEffect(() => {
    const fetch = async () => {
      const articles = await refresh();

      const selectedArticle = articles.find(
        (item) => item.reference === reference,
      );
      setSelected(selectedArticle);

      setLoading(false);
    };

    fetch();
  }, [refresh, number]);

  const onOK = () => {
    onChange({
      gen_art_nr: gen_arts?.[0],
      ...selected,
    });
  };

  const onAddDealerPart = (article) => {
    if (article.description && article.price) {
      onChange({
        gen_art_nr: gen_arts?.[0],
        ...article,
      });
      setShowAddEditPartDialog(false);
    }
  };

  const onEditedDealerPart = async (article) => {
    onChange({
      gen_art_nr: gen_arts?.[0],
      ...article,
    });

    setSelected(article);
    setEditCustomPart(null);
    setShowAddEditPartDialog(false);
  };

  const onDeletedDealerPart = async () => {
    if (articles.length > 1) {
      await refresh();
    } else {
      onRemove();
    }

    setSelected(null);
    setEditCustomPart(null);
    setShowAddEditPartDialog(false);
  };

  return (
    <>
      {sla_part ? (
        <SlaArticleDialog
          request_id={request_id}
          sla_part={sla_part}
          price={task_part?.price ?? sla_part?.price}
          itemMpId={item_mp_id}
          attributes={task_part?.attributes}
          specifications={task_part?.specifications}
          task_part_id={task_part?.id}
          onRemove={onRemove}
          onDismiss={onDismiss}
          onChange={(specifications) => {
            onChange({
              gen_art_nr: gen_arts?.[0],
              ...selected,
              specifications,
            });
          }}
        />
      ) : (
        <Actionsheet
          visible={true}
          dismissable={dismissable}
          onDismiss={onDismiss}>
          {loading && (
            <View style={[sy['p-4']]}>
              <ActivityIndicator size="large" color="#231fda" />
            </View>
          )}
          {!loading && (
            <>
              <Title>
                <View
                  style={[
                    sy['flex-row'],
                    sy['justify-between'],
                    sy['items-center'],
                    sy['flex-1'],
                    sy['gap-4'],
                    {
                      width: '100%',
                    },
                  ]}>
                  <Text style={[sy.largePlus, sy.truncate, {lineHeight: 30}]}>
                    {description ?? <>Artikel</>}
                  </Text>
                  {onRemove && (
                    <Pressable onPress={onRemove}>
                      <DeleteIcon fill="#4a4a49" />
                    </Pressable>
                  )}
                </View>
              </Title>
              <Divider />
              <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
                <View
                  style={[sy['flex-row'], sy['px-4'], sy['py-3'], sy['gap-2']]}>
                  <Chip
                    active={FILTER.history === showFilter}
                    onPress={() => setShowFilter(FILTER.history)}>
                    Eerder gekozen
                  </Chip>
                  {slaArticleBrands.length > 0 && (
                    <Chip
                      active={FILTER.preferred === showFilter}
                      onPress={() => setShowFilter(FILTER.preferred)}>
                      Voorkeur
                    </Chip>
                  )}
                  <Chip
                    active={FILTER.all === showFilter}
                    onPress={() => setShowFilter(FILTER.all)}>
                    Alles
                  </Chip>
                </View>
                <Divider />
                <ScrollView
                  contentContainerStyle={{
                    maxHeight:
                      dimensions.window.height -
                      HEADER_HEIGHT_IN_PX -
                      FOOTER_HEIGHT_IN_PX,
                  }}>
                  {articles
                    ?.filter((item) => {
                      if (item.reference !== task_part?.reference) {
                        if (
                          ((showFilter === FILTER.all && !showAllArticles) ||
                            showFilter === FILTER.history) &&
                          articlesFilter?.groups?.length > 0
                        ) {
                          if (
                            articlesFilter.groups.every(
                              (group) => group !== item.product_group,
                            )
                          ) {
                            return false;
                          }
                        }
                      }

                      if (showFilter === FILTER.history) {
                        return item.rob_article;
                      }

                      if (showFilter === FILTER.preferred) {
                        return slaArticleBrands.includes(item.brand);
                      }

                      return true;
                    })
                    .sort((a, b) => {
                      if (a.rob_article && !b.rob_article) {
                        return -1;
                      }

                      if (!a.rob_article && b.rob_article) {
                        return 1;
                      }

                      if (!_.isNumber(a.margin) && !_.isNumber(b.margin)) {
                        return a.description.localeCompare(b.description);
                      }

                      if (!_.isNumber(a.margin)) {
                        return -1;
                      }

                      if (!_.isNumber(b.margin)) {
                        return 1;
                      }

                      if (a.margin === b.margin) {
                        return a.description.localeCompare(b.description);
                      }

                      return b.margin - a.margin;
                    })
                    .map((item) => (
                      <View
                        key={item.id ?? item.description}
                        style={[sy['flex-row']]}>
                        <Pressable
                          style={[sy['p-4'], sy['pr-8']]}
                          onPress={() => setSelected(item)}>
                          <RadioButton checked={selected?.id === item.id} />
                        </Pressable>
                        <View
                          style={[
                            sy['flex-row'],
                            sy['flex-1'],
                            sy['justify-between'],
                            sy['py-4'],
                            sy['pr-4'],
                            sy['border-b'],
                            sy['border-lightgray'],
                          ]}>
                          <View style={[sy['flex-1']]}>
                            <Text style={[sy.truncate]}>
                              {item.description}
                            </Text>
                            {item.source === SOURCES.Custom ? (
                              <Link
                                textStyle={[sy.small, sy['text-lightgray']]}
                                onPress={() => {
                                  setSelected(item);
                                  setEditCustomPart(item);
                                  setShowAddEditPartDialog(true);
                                }}>
                                <ArticleText
                                  source={item.source}
                                  number={item.number}
                                />
                              </Link>
                            ) : item.source === SOURCES.Fource ? (
                              <FourceDetailsLink
                                license={license}
                                dealer_id={dealer_id}
                                article={item}
                              />
                            ) : (
                              <Text style={[sy.small, sy['text-lightgray']]}>
                                <ArticleText
                                  source={item.source}
                                  number={item.number}
                                />
                              </Text>
                            )}
                          </View>
                          <View style={sy['items-end']}>
                            <Text style={sy.smallRegular}>
                              {Format.price(item.price)}
                            </Text>
                            <View style={[sy['flex-row'], sy['gap-2']]}>
                              {item.margin > 0 && (
                                <Text style={[sy.small, sy['text-lightgray']]}>
                                  {Format.number(item.margin * 100, 0)}%
                                </Text>
                              )}
                              {slaArticleBrands.includes(item.brand) && (
                                <ArticleTooltip lessor={lessor} />
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    ))}
                  {canAddPart &&
                    showFilter === FILTER.all &&
                    !showAllArticles &&
                    articlesFilter?.groups?.length > 0 && (
                      <Pressable
                        style={[sy['p-4'], sy['flex-row']]}
                        onPress={() => setShowAllArticles(true)}>
                        <View style={[sy['pr-8']]}>
                          <UnfoldMoreIcon fill="#231fda" />
                        </View>
                        <Text>Meer artikelen tonen</Text>
                      </Pressable>
                    )}
                  {canAddPart &&
                    showFilter === FILTER.all &&
                    (articlesFilter?.groups?.length > 0
                      ? showAllArticles
                      : true) && (
                      <Pressable
                        style={[sy['p-4'], sy['flex-row']]}
                        onPress={() => setShowAddEditPartDialog(true)}>
                        <View style={[sy['pr-8']]}>
                          <AddIcon fill="#231fda" />
                        </View>
                        <Text>Artikel invoeren</Text>
                      </Pressable>
                    )}
                </ScrollView>
              </Content>
              <Divider />
              <Actions>
                <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
                <ActionButton onPress={onOK}>OK</ActionButton>
              </Actions>
            </>
          )}
          {showAddEditPartDialog && (
            <AddPartDialog
              dealer_id={dealer_id}
              license={license}
              item_mp_id={item_mp_id}
              edit_article={editCustomPart}
              onDismiss={() => {
                setEditCustomPart(null);
                setShowAddEditPartDialog(false);
              }}
              onAdd={onAddDealerPart}
              onEdited={onEditedDealerPart}
              onDeleted={onDeletedDealerPart}
            />
          )}
        </Actionsheet>
      )}
    </>
  );
};

export default withDimensions(AddArticle);
