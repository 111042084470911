import React, {useEffect, useState} from 'react';
import {View, ScrollView, ActivityIndicator} from 'react-native';
import {Divider} from 'react-native-paper';

import sy from '~/styles';
import {
  Text,
  RadioButton,
  ActionButton,
  Pressable,
} from '~/components/controls';
import Actionsheet, {
  Title,
  Content,
  Actions,
} from '~/components/controls/Actionsheet';
import {rob as robApi} from '~/api/private';
import withDimensions from '~/components/hoc/with-dimensions';

const DIALOG_HEADER_IN_PIXELS = 71;
const DIALOG_FOOTER_IN_PIXELS = 51;

const Operation = withDimensions(
  ({
    dimensions,
    onDismiss,
    license,
    request_id,
    rob_code,
    operation_code,
    reason_code,
    onChange,
  }) => {
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState({
      operation: operation_code,
      reason: reason_code,
    });
    const [operations, setOperations] = useState([]);

    useEffect(() => {
      const fetch = async () => {
        if (rob_code) {
          const {result: rob_component} = await robApi.component(
            license,
            request_id,
            rob_code,
          );

          setOperations(rob_component[rob_code]?.operations);

          if (!operation_code) {
            setSelected({
              operation: rob_component[rob_code]?.operations[0]?.operation.code,
              reason: rob_component[rob_code]?.operations[0]?.reasons[0]?.code,
            });
          }
        }

        setLoading(false);
      };

      fetch();
    }, [request_id, license, rob_code, operation_code]);

    const onOK = () => {
      onChange(selected);
    };

    return (
      <Actionsheet visible={true} onDismiss={onDismiss}>
        {loading && (
          <View style={[sy['p-4']]}>
            <ActivityIndicator size="large" color="#231fda" />
          </View>
        )}
        {!loading && (
          <>
            <Title>Bewerking&nbsp;&middot;&nbsp;Reden</Title>
            <Divider />
            <Content style={{paddingBottom: 0, paddingHorizontal: 0}}>
              <ScrollView
                contentContainerStyle={{
                  maxHeight:
                    dimensions.window.height -
                    DIALOG_HEADER_IN_PIXELS -
                    DIALOG_FOOTER_IN_PIXELS,
                }}>
                {operations.map(({operation, reasons}) => (
                  <View key={operation.code}>
                    <View style={[sy['p-4']]}>
                      <Pressable
                        style={[
                          sy['items-center'],
                          sy['flex-row'],
                          sy['gap-4'],
                        ]}
                        onPress={() => {
                          setSelected({
                            operation: operation.code,
                            reason: reasons[0]?.code,
                          });
                        }}>
                        <RadioButton
                          checked={selected.operation === operation.code}
                          disabledTimeout={0}
                          onPress={() => {
                            setSelected({
                              operation: operation.code,
                              reason: reasons[0]?.code,
                            });
                          }}
                        />
                        <Text>{operation.description}</Text>
                      </Pressable>
                    </View>
                    {selected.operation === operation.code &&
                      reasons.map((reason) => (
                        <View key={reason.code}>
                          <View style={[sy['p-4'], sy['pl-8']]}>
                            <Pressable
                              style={[
                                sy['items-center'],
                                sy['flex-row'],
                                sy['gap-4'],
                              ]}
                              onPress={() => {
                                setSelected({
                                  operation: operation.code,
                                  reason: reason.code,
                                });
                              }}>
                              <RadioButton
                                checked={selected.reason === reason.code}
                              />
                              <Text>{reason.description}</Text>
                            </Pressable>
                          </View>
                        </View>
                      ))}
                  </View>
                ))}
              </ScrollView>
            </Content>
            <Divider />
            <Actions>
              <ActionButton onPress={onDismiss}>Annuleren</ActionButton>
              <ActionButton onPress={onOK}>OK</ActionButton>
            </Actions>
          </>
        )}
      </Actionsheet>
    );
  },
);

export default Operation;
