import React, {
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {View, ActivityIndicator, ScrollView} from 'react-native';
import {Divider} from 'react-native-paper';
import moment from 'moment';
import {HeaderBackButton} from '@react-navigation/elements';
import {useNavigation, useIsFocused} from '@react-navigation/native';

import sy from '~/styles';
import withProtectedScreen from '~/components/hoc/with-protected-screen';
import {showClientMessagesDialog} from '~/actions';
import {service_request as serviceRequestApi} from '~/api/private';
import {
  Text,
  InputChat,
  ServiceRequestStatusIcon,
  Pressable,
  Link,
} from '~/components/controls';
import {Message} from '~/components/shared';
import {useDispatch, useSelector} from '~/lib/hooks';
import {STATUS_CODES, STATUS_DESCRIPTIONS} from '~/types/statuses';
import {isLessorStatus} from '~/types/rob';
import {REQUEST_TYPES, IsReplacementVehicle} from '~/types/request';
import {DealerReminderDialog} from '~/components/shared/Dialogs';
import Format from '~/lib/format';

import ChevronLeftIcon from '~/images/md-icons/chevron_left/materialicons/24px.svg';
import TextsmsIcon from '~/images/md-icons/textsms/materialicons/24px.svg';
import EmailIcon from '~/images/md-icons/email/materialicons/24px.svg';

const Activity = ({
  request_id,
  parent_id,
  request_type,
  activity_id,
  attributes,
  status,
  display_timestamp,
  is_last_activity,
}) => {
  const navigation = useNavigation();

  useEffect(() => {
    serviceRequestApi.activity_read(request_id, activity_id);
  }, []);

  const onPress = () => {
    if (IsReplacementVehicle(request_type)) {
      navigation.navigate('Replacement', {request_id: parent_id});
    } else {
      navigation.navigate('Request', {request_id: request_id});
    }
  };

  const link_content = (
    <>
      {is_last_activity && (
        <>
          &nbsp;&middot;&nbsp;
          <Link
            textStyle={[sy.xSmallMedium, {color: '#2907E3'}]}
            onPress={onPress}>
            Bekijken
          </Link>
        </>
      )}
    </>
  );

  if (attributes?.type === 'SMS') {
    return (
      <View
        style={[
          {
            borderRadius: 18,
          },
          sy['bg-gray95'],
          sy['py-2'],
          sy['px-4'],
          sy['flex-row'],
          sy['items-center'],
          sy['gap-6'],
        ]}>
        <TextsmsIcon fill="#4a4a49" width={18} height={18} />
        <View
          style={[
            sy['flex-1'],
            sy['flex-row'],
            sy['justify-between'],
            {paddingRight: 6},
          ]}>
          <Text style={sy.smallMedium}>
            {status === 'SentFailed'
              ? 'SMS verzenden mislukt'
              : 'SMS verzonden'}
            {link_content}
          </Text>
          <Text style={[sy.smallRegular]}>
            {display_timestamp.format('H:mm')}
          </Text>
        </View>
      </View>
    );
  } else if (attributes?.type === 'Email') {
    return (
      <View
        style={[
          {
            borderRadius: 18,
          },
          sy['bg-gray95'],
          sy['py-2'],
          sy['px-4'],
          sy['flex-row'],
          sy['items-center'],
          sy['gap-6'],
        ]}>
        <EmailIcon fill="#4a4a49" width={18} height={18} />
        <View
          style={[
            sy['flex-1'],
            sy['flex-row'],
            sy['justify-between'],
            {paddingRight: 6},
          ]}>
          <Text style={sy.smallMedium}>
            E-mail verzonden
            {link_content}
          </Text>
          <Text style={[sy.smallRegular]}>
            {display_timestamp.format('H:mm')}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View
      style={[
        {
          borderRadius: 18,
        },
        sy['bg-gray95'],
        sy['py-2'],
        sy['px-4'],
        sy['flex-row'],
        sy['items-center'],
        sy['gap-6'],
      ]}>
      <ServiceRequestStatusIcon
        status={status}
        fill={!is_last_activity ? '#828282' : null}
        iconProps={{
          width: 18,
          height: 18,
        }}
      />
      <View
        style={[
          sy['flex-1'],
          sy['flex-row'],
          sy['justify-between'],
          {paddingRight: 6},
        ]}>
        <Text
          style={[sy.smallMedium, !is_last_activity && sy['text-lightgray']]}>
          {STATUS_DESCRIPTIONS[status] ?? status}
          {link_content}
        </Text>
        <Text style={[sy.smallRegular]}>
          {display_timestamp.format('H:mm')}
        </Text>
      </View>
    </View>
  );
};

const Chat = ({navigation, route}) => {
  const request_id = route.params?.request_id;

  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const forceRefresh = useSelector((state) => state.serviceRequestUpdate);
  const lastFetch = useSelector((state) => state.serviceRequestLastFetch);

  const [overview, setOverview] = useState(null);
  const [is_fleet_car, setIsFleetCar] = useState(false);
  const [is_retail_car, setIsRetailCar] = useState(false);
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(false);
  const [pendingMessage, setPendingMessage] = useState(null);

  const [showReminderDialog, setShowReminderDialog] = useState(false);

  const scrollViewRef = useRef(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <HeaderBackButton
          backImage={() => <ChevronLeftIcon />}
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack();
            } else {
              navigation.replace('Request', {
                request_id,
              });
            }
          }}
        />
      ),
      headerRight: () => {
        if (
          (is_retail_car || overview?.status === STATUS_CODES.Done) &&
          (overview?.phone_number?.length > 0 || overview?.email?.length > 0)
        ) {
          return (
            <Pressable
              style={[sy['pr-4']]}
              onPress={() => {
                if (overview?.status === STATUS_CODES.Done) {
                  dispatch(
                    showClientMessagesDialog(
                      request_id,
                      overview?.phone_number,
                      overview?.email,
                    ),
                  );
                } else if (is_retail_car) {
                  setShowReminderDialog(true);
                }
              }}>
              <TextsmsIcon fill="#4a4a49" />
            </Pressable>
          );
        }

        return null;
      },
      headerStatusBarHeight: 0,
      headerTitleAlign: 'center',
      headerTitle: () =>
        loading ? null : (
          <View style={{alignItems: 'center'}}>
            <Text style={sy.medium}>{Format.license(overview?.license)}</Text>
          </View>
        ),
    });
  }, [navigation, request_id, overview, loading, is_retail_car]);

  const fetch = useCallback(async () => {
    const {result: overview} = await serviceRequestApi.get(
      request_id,
      dispatch,
    );
    const {result} = await serviceRequestApi.messages(request_id);
    const {messages} = result;

    setOverview(overview[0]);
    setIsFleetCar(overview[0].request_type === REQUEST_TYPES.FLEET_REQUEST);
    setIsRetailCar(overview[0].request_type === REQUEST_TYPES.SERVICE_REQUEST);
    setMessages(messages);
    setEditable(
      !isLessorStatus(overview[0].rob?.status) &&
        overview[0].status !== STATUS_CODES.Done &&
        overview[0].status !== STATUS_CODES.Invoiced &&
        overview[0].status !== STATUS_CODES.Archived,
    );
    setLoading(false);
  }, [request_id, dispatch]);

  useEffect(() => {
    if (request_id && isFocused) {
      fetch();
    }
  }, [request_id, isFocused, fetch]);

  useEffect(() => {
    const last_fetch_date = moment(lastFetch[request_id] ?? new Date(0));
    const update_date = moment(
      forceRefresh[request_id]?.timestamp ?? new Date(0),
    );

    if (update_date.isAfter(last_fetch_date, 'seconds')) {
      console.log(
        'Refreshing service_request messages',
        request_id,
        last_fetch_date,
        update_date,
      );

      fetch();
    }
  }, [request_id, forceRefresh, lastFetch, fetch]);

  const onSend = async () => {
    if (!editable) {
      return;
    }

    const {success} = await serviceRequestApi.send_message(
      request_id,
      pendingMessage,
    );

    if (success) {
      setPendingMessage(null);
      fetch();
    }
  };

  const onAttachment = async (attachment) => {
    if (!editable) {
      return;
    }

    const {success} = await serviceRequestApi.send_attachment(
      request_id,
      attachment,
    );

    if (success) {
      fetch();
    }
  };

  const onEditMessage = async (message_id, value) => {
    const {success} = await serviceRequestApi.edit_message(request_id, {
      id: message_id,
      content: value,
    });

    if (success) {
      fetch();
    }
  };

  const onDeleteMessage = async (message_id) => {
    const {success} = await serviceRequestApi.delete_message(request_id, {
      id: message_id,
    });

    if (success) {
      fetch();
    }
  };

  const last_activity_id = Object.values(messages ?? {})
    .flatMap((item) => item)
    .filter((item) => item.activity_id)
    .slice(-1)?.[0]?.activity_id;

  return (
    <>
      <View style={sy.mainView}>
        <ScrollView
          style={[sy['flex-1'], sy['p-4']]}
          contentContainerStyle={[sy['gap-4']]}
          ref={scrollViewRef}
          onContentSizeChange={() =>
            scrollViewRef.current?.scrollToEnd({animated: true})
          }>
          {loading && <ActivityIndicator size="large" color="#231fda" />}
          {!loading &&
            messages &&
            Object.keys(messages).map((key) => (
              <View key={key} style={[sy['gap-2']]}>
                <Text
                  style={[
                    sy.smallPlus,
                    {
                      textAlign: 'center',
                    },
                  ]}>
                  {moment(key, 'DD-MM-YYYY').format('D MMMM YYYY')}
                </Text>
                <View style={[sy['gap-4']]}>
                  {messages[key].map((item) => {
                    const {
                      activity_id,
                      status,
                      timestamp,
                      attributes,
                      ...message
                    } = item;

                    // Create a new timestamp with date set to today, as we only need to display the time.
                    // Is it a hack? Maybe...
                    const display_timestamp = moment();
                    display_timestamp.set(
                      'hours',
                      moment(timestamp).get('hours'),
                    );
                    display_timestamp.set(
                      'minutes',
                      moment(timestamp).get('minutes'),
                    );

                    if (activity_id) {
                      return (
                        <Activity
                          key={activity_id}
                          request_id={request_id}
                          parent_id={item.parent_id}
                          request_type={item.request_type}
                          activity_id={activity_id}
                          attributes={attributes}
                          status={status}
                          display_timestamp={display_timestamp}
                          is_last_activity={activity_id === last_activity_id}
                        />
                      );
                    } else {
                      const message_editable =
                        editable && message?.user_id && !message?.is_sent;

                      return (
                        <Message
                          key={message.message_id}
                          {...message}
                          timestamp={display_timestamp}
                          onEdit={message_editable ? onEditMessage : null}
                          onDelete={message_editable ? onDeleteMessage : null}
                        />
                      );
                    }
                  })}
                </View>
              </View>
            ))}
        </ScrollView>
        {editable && (
          <>
            <Divider />
            <InputChat
              message={pendingMessage}
              onChange={setPendingMessage}
              onSend={onSend}
              onAttachment={
                !overview || IsReplacementVehicle(overview?.request_type)
                  ? null
                  : onAttachment
              }
            />
          </>
        )}
      </View>
      {showReminderDialog && (
        <DealerReminderDialog
          visible={true}
          request_id={request_id}
          phone_number={overview?.phone_number}
          email={overview?.email}
          onDismiss={() => setShowReminderDialog(false)}
        />
      )}
    </>
  );
};

export default withProtectedScreen(Chat);
